<template>
    <div class="grid">


        <div class="col-6">

            <h1>Ingeniería 2A</h1>

            <h2>Prueba de maestro con código: </h2>

            <input type="text" v-model="codigo" placeholder="Código" />

            <hr />

            <div class="" v-if="codigo && codigo.length >= 3">


                <div v-if="codigo.length >= 3">


                    <h5>Pasaje</h5>
                    {{ requestdata.modpasaje[0]['description'] }}
                </div>

                <div v-if="codigo.length >= 4">
                    <h5>Caracteristica
                    </h5>
                    {{ requestdata.carac[0]['description'] }}
                </div>

                <div v-if="codigo.length >= 6">
                    <h5>Tamaño

                    </h5>
                    {{ requestdata.tamano[0]['description'] }}

                </div>


                <div v-if="codigo.length >= 7">
                    <h5>Aplicaciones


                    </h5>
                    {{ requestdata.aplic[0]['description'] }}


                </div>

                <hr />
            </div>


        </div>

        <div class="col-6">
            <h4>Prueba de maestro con desplegables</h4>
            <div
                class="mb-4 bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                <div class="font-bold mr-8">CODIGO</div>
                <div v-if="form.pasaje" class=" mr-8">

                    <span>
                        {{ form.pasaje.code.trim() }}
                    </span><span v-if="form.caract">{{ form.caract.code }}</span>
                    <span v-if="form.tamano">{{ form.tamano.code }}</span>
                    <span v-if="form.aplic">{{ form.aplic.code }}</span>
                    <span v-if="form.aplic2">{{ form.aplic2.code }}</span>
                    <span v-if="form.matcuerpo">{{ form.matcuerpo.code }}</span>
                    <!-- matesfera, matvastago, tipoasiento, matjunta, extremos, montaje, extremos2, especial -->
                    <span v-if="form.matesfera">{{ form.matesfera.code }}</span>
                    <span v-if="form.matvastago">{{ form.matvastago.code }}</span>
                    <span v-if="form.tipoasiento">{{ form.tipoasiento.code }}</span>
                    <span v-if="form.matjunta">{{ form.matjunta.code }}</span>
                    <span v-if="form.extremos">{{ form.extremos.code }}</span>
                    <span v-if="form.montaje">{{ form.montaje.code }}</span>
                    <span v-if="form.extremos2">{{ form.extremos2.code }}</span>
                    <span v-if="form.especial">{{ form.especial.code }}</span>
                    <!-- revision -->
                    <span v-if="form.revision">{{ form.revision.code }}</span>

                </div>
                <!-- 
 {{ form }} -->
            </div>



            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="pasaje" forceSelection v-model="form.pasaje"
                        :suggestions="filteredSearch" @complete="searchType($event, 'modpasaje')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Pasaje</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete :disabled="!form.pasaje" class="" id="caract" forceSelection v-model="form.caract"
                        :suggestions="filteredSearch" @complete="searchType($event, 'caract')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Caracteristica</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete :disabled="!form.caract" class="" id="tamano" forceSelection v-model="form.tamano"
                        :suggestions="filteredSearch" @complete="searchType($event, 'tamano')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Tamaño</label>
                </span>
            </div>

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="aplic" :disabled="!form.tamano" forceSelection v-model="form.aplic"
                        :suggestions="filteredSearch" @complete="searchType($event, 'aplic')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Apliaciones</label>
                </span>
            </div>


            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="aplic2" :disabled="!form.aplic" forceSelection v-model="form.aplic2"
                        :suggestions="filteredSearch" @complete="searchType($event, 'aplic2')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">2 da Aplicación
                    </label>
                </span>
            </div>


            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="matcuerpo" :disabled="!form.aplic2" forceSelection v-model="form.matcuerpo"
                        :suggestions="filteredSearch" @complete="searchType($event, 'matcuerpo')" :dropdown="true"
                        field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Material Cuerpo
                    </label>
                </span>
            </div>

            <!--- matesfera -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="matesfera" :disabled="!form.matcuerpo" forceSelection
                        v-model="form.matesfera" :suggestions="filteredSearch" @complete="searchType($event, 'matesfera')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Material Esfera /OBTURADOR /CLAPETA/ ELEM. FILTRANTE
                    </label>
                </span>

            </div>

            <!-- matvastago -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="matvastago" :disabled="!form.matesfera" forceSelection
                        v-model="form.matvastago" :suggestions="filteredSearch" @complete="searchType($event, 'matvastago')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Mat. Vástago / EJE /VASTAGO	

                    </label>
                </span>

            </div>

            <!-- tipoasiento -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="tipoasiento" :disabled="!form.matvastago" forceSelection
                        v-model="form.tipoasiento" :suggestions="filteredSearch" @complete="searchType($event, 'tipoasiento')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Tipo Asiento
                    </label>
                </span>
            </div>

            <!-- matjunta -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="matjunta" :disabled="!form.tipoasiento" forceSelection
                        v-model="form.matjunta" :suggestions="filteredSearch" @complete="searchType($event, 'matjunta')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Material Junta
                    </label>
                </span>
                
            </div>

            <!-- extremos -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="extremos" :disabled="!form.matjunta" forceSelection
                        v-model="form.extremos" :suggestions="filteredSearch" @complete="searchType($event, 'extremos')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Extremos
                    </label>
                </span>

            </div>

            <!-- montaje -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="montaje" :disabled="!form.extremos" forceSelection
                        v-model="form.montaje" :suggestions="filteredSearch" @complete="searchType($event, 'montaje')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Montaje
                    </label>
                </span>

            </div>

            <!-- extremos2 -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="extremos2" :disabled="!form.montaje" forceSelection
                        v-model="form.extremos2" :suggestions="filteredSearch" @complete="searchType($event, 'extremos2')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">2º Extremo	

                    </label>
                </span>

            </div>

            <!-- especial -->

            <div class="field col-12 md:col-12">
                <span class="p-float-label p-fluid">

                    <AutoComplete class="" id="especial" :disabled="!form.extremos2" forceSelection
                        v-model="form.especial" :suggestions="filteredSearch" @complete="searchType($event, 'especial')"
                        :dropdown="true" field="description">
                        <template #item="slotProps">
                            <div class="country-item">

                                <div>{{ slotProps.item.description }}</div>
                            </div>
                        </template>
                    </AutoComplete>
                    <label for="inputtext-right">Aplicación Especial
                    </label>
                </span>

            </div>

            <hr />


            <div>

                <!--- Revisión -->

                <div class="field col-12 md:col-12 flex">
                    <span class="p-float-label">

                        <AutoComplete class="" id="revision" :disabled="!form.especial" forceSelection v-model="form.revision"
                            :suggestions="filteredSearch" @complete="searchType($event, 'revision')" :dropdown="true"
                            field="description">
                            <template #item="slotProps">
                                <div class="country-item">

                                    <div>{{ slotProps.item.description }}</div>
                                </div>
                            </template>
                        </AutoComplete>
                        <label for="inputtext-right">Revisión
                        </label>
                    </span>

                    <button :disabled="!form.revision" class="p-button p-component p-button-secondary  ml-3"
                        @click="addRevision">
                        Generar

                        </button>   

                </div>

            </div>



        </div>
    </div>
</template>

<script>
import CrudService from "./../../../services/crud.service.js";

export default {
    name: "Maestro",
    data() {
        return {
            // data
            codigo: null,

            requestdata: null,

            alldata: null,
            pasajes: null,

            form: {
                pasaje: null,
            }
            ,
            filteredSearch: null,

        }
    },
    methods: {
        // methods
        searchPasajes(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredPasajes = [...this.pasajes];
                }
                else {
                    this.filteredPasajes = this.pasajes.filter((pasaje) => {
                        return pasaje['description'] && pasaje['description'].toLowerCase().includes(event.query.toLowerCase());
                    });
                }
            }, 250);
        },

        searchType(event, type) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredSearch = this.alldata.filter((item) => {
                        return item.type == type && item.code && item.description;
                    });
                }
                else {
                    this.filteredSearch = this.alldata.filter((pasaje) => {
                        return pasaje['description'] && pasaje['description'].toLowerCase().includes(event.query.toLowerCase()) && pasaje.type == type && pasaje.code && pasaje.description;
                    });
                }
            }, 250);
        }
    },
    computed: {
        // computed
    },
    watch: {

        codigo: function (val) {

            let mod = this.alldata.filter((item) => {
                return item.code == val[0] + val[1] + val[2] && item.type == 'modpasaje' && item.code;
            });
            let carac = this.alldata.filter((item) => {
                return item.code == val[3] && item.type == 'caract' && item.code;
            });
            let tamano = this.alldata.filter((item) => {
                return item.code == val[4] + val[5] && item.type == 'tamano' && item.code;
            });

            let aplic = this.alldata.filter((item) => {
                return item.code == val[6] && item.type == 'aplic' && item.code;
            });



            this.requestdata = {
                modpasaje: mod,
                carac: carac,
                tamano: tamano,
                aplic: aplic
            };
        }
        // watch
    },
    created() {
        // created axios get

        CrudService.getMaestro2A().then(
            response => {
                this.alldata = response;

                this.pasajes = this.alldata.filter((item) => {
                    return item.type == 'modpasaje' && item.code;
                });

            },
            error => {

            }
        );



    },
    mounted() {
        // mounted
    },
    beforeDestroy() {
        // beforeDestroy
    },
    destroyed() {
        // destroyed
    }
}

</script>